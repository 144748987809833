<template>
  <div>
    <b-modal
      id="personal-leave-entitlement-modal"
      title="Apply New Leave"
      title-class="h4 font-weight-bold"
      lazy
      size="lg"
      hide-footer
    >
      <PersonalLeaveEntitlementApplyLeaveForm
        class="mx-2"
        :is-busy="isBusy"
        @submit-clicked="submitForm"
      ></PersonalLeaveEntitlementApplyLeaveForm>
    </b-modal>
  </div>
</template>

<script>
import PersonalLeaveEntitlementApplyLeaveForm from "@/modules/personal/components/leave/entitlement/modal/PersonalLeaveEntitlementApplyLeaveForm";

export default {
  name: "PersonalLeaveEntitlementModal",
  components: { PersonalLeaveEntitlementApplyLeaveForm },
  props: {
    form: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isBusy: false,
    };
  },
  methods: {
    /**
     * Submit form via store.
     *
     * @param form
     */
    submitForm(form) {
      this.isBusy = true;
      this.$store
        .dispatch("submitPersonalLeaveApplicationForm", {
          form: {
            from_date: form.from,
            to_date: form.to,
            employee_id: this.form.teacher_id ?? this.form.staff_id,
            id: this.form.leave.school_leave.id,
            remark: form.remark,
          },
        })
        .then((res) => {
          this.$bvModal.msgBoxOk(this.$t("ALERT.SUCCESS"));
          this.$emit("formSubmitted", res);
        })
        .catch(() => {
          this.$bvModal.msgBoxOk(this.$t("ALERT.ERROR"), {
            okVariant: "danger",
          });
        })
        .finally(() => (this.isBusy = false));
    },
  },
};
</script>

<style scoped></style>
